import React from "react"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"

import { graphql } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"

import Layout from "../components/layout"

const useStyles = makeStyles(theme => ({
  root: {
    padding: "180px 20px",
    maxWidth: 1128,
    [theme.breakpoints.down("sm")]: {
      padding: "100px 20px 100px",
    },
    "& a": {
      color: "#363654",
      textDecoration: "underline",
      "&:hover": {
        textDecoration: "none",
      },
      "&:hover:active": {
        textDecoration: "none",
      },
    },
  },
  pageTitle: {
    margin: "80px auto 140px",
    [theme.breakpoints.down("sm")]: {
      margin: "40px auto 60px",
    },
  },
  pageContent: {},
}))

const PrivacyPage = props => {
  const classes = useStyles()

  const {
    datoCmsPrivacyPage: { privacyTitle, privacyDescription },
  } = props.data

  return (
    <Layout>
      <Container className={classes.root}>
        <Typography
          align="center"
          variant="h1"
          component="h1"
          className={classes.pageTitle}
        >
          {privacyTitle}
        </Typography>
        <Typography
          variant="body1"
          className={classes.pageContent}
          component="div"
          dangerouslySetInnerHTML={{ __html: privacyDescription }}
        />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    datoCmsPrivacyPage {
      privacyDescription
      privacyTitle
    }
  }
`

export default PrivacyPage
